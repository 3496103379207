var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal card",
      class: { "is-active": _vm.showHistory },
      staticStyle: { "z-index": "1006 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c(
          "section",
          { staticClass: "modal-card-body notification" },
          [
            _c(
              "button",
              { staticClass: "delete", on: { click: _vm.closeModal } },
              [_vm._v("close")]
            ),
            _c("h3", { staticClass: "title is-6 has-text-success" }, [
              _vm._v("History of actions"),
            ]),
            _vm._l(_vm.snapshots, function (snapshot) {
              return _c(
                "a",
                {
                  key: snapshot.id,
                  staticClass: "panel-block",
                  staticStyle: {
                    "background-color": "white",
                    "text-decoration": "none",
                    color: "black",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(snapshot.action) +
                      " - " +
                      _vm._s(
                        _vm
                          .$moment(_vm.$moment(snapshot.date).toDate())
                          .format("MMMM Do dddd h:mm A")
                      ) +
                      " "
                  ),
                ]
              )
            }),
            _c("div", { staticClass: "panel-block" }, [
              _c(
                "button",
                {
                  staticClass: "button is-info is-fullwidth is-small",
                  class: _vm.undoLoading ? " is-loading" : "",
                  on: { click: _vm.getLatestAction },
                },
                [_vm._v(" Undo ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button is-warning is-fullwidth is-small",
                  class: _vm.undoLoading ? " is-loading" : "",
                },
                [_vm._v(" Delete ")]
              ),
            ]),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }