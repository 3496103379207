import {
  insertZone,
  deleteZone,
  EditShapeZone,
  deleteHistory,
  changeZoneId
} from '@/store/mongoStore'

import {
  deleteZoneInfo,
  insertZoneInfoWithID,
  editZoneInfo
} from '@/store/fireStore'

import {
  DELETE_ZONE,
  EDIT_COORDINATES_ZONE,
  INSERT_ZONE,
  INSERT_ZONE_INFO,
  DELETE_ZONE_INFO,
  EDIT_ZONE_INFO,
  CHANGE_ZONE_ID,
  EDIT_COORDINATES_ZONE_MULTIPLE
} from '../exports/historyConstants'

/* todo: change name to named export UNDO HISTORY calledUndo() */
export default async (action: string, payload: any, latestID: string) => {
  if (action === EDIT_COORDINATES_ZONE) {
    const { current: { coordinates, fsa, multi }, zoneShapeId } = payload
    await EditShapeZone({
      coordinates: JSON.parse(coordinates),
      shapeId: zoneShapeId,
      multi,
      fsa,
      calledUndo: true
    })
    return await deleteHistory((latestID))
  } else if (action === INSERT_ZONE) {
    const { zoneShapeId } = payload
    await deleteZone(zoneShapeId, true)
    return await deleteHistory((latestID))
  } else if (action === DELETE_ZONE) {
    const { coordinates, fsa, multi, shapesID, zoneShapeId } = payload
    await insertZone(
      JSON.parse(coordinates),
      shapesID,
      multi,
      fsa,
      true,
      zoneShapeId
    )
    return await deleteHistory((latestID))
  } else if (action === INSERT_ZONE_INFO) {
    const { zoneId } = payload
    const deleted = await deleteZoneInfo(zoneId, true)
    if (deleted) return await deleteHistory((latestID))
    //
  } else if (action === DELETE_ZONE_INFO) {
    const { color, region, value, key, selected, environment, zoneId } = payload
    insertZoneInfoWithID({ color, region, value, key, selected, environment }, zoneId, true)
    await deleteHistory((latestID))
  } else if (action === EDIT_ZONE_INFO) {
    const { current, zoneId } = payload
    editZoneInfo(zoneId, { ...current }, true)
    await deleteHistory((latestID))
  } else if (action === CHANGE_ZONE_ID) {
    const { shapesID, zoneShapeId } = payload
    await changeZoneId(shapesID, zoneShapeId, true)
    await deleteHistory((latestID))
  } else if (action === EDIT_COORDINATES_ZONE_MULTIPLE) {
    const { newInsertedId, currentShape, shapeType } = payload
    const { coordinates, fsa, multi, shapesID, zoneShapeId } = currentShape
    await newInsertedId.map(async (id: string) => {
      await deleteZone(id.toString(), true)
    })
    await insertZone(
      JSON.parse(coordinates),
      shapesID,
      multi,
      fsa,
      true,
      zoneShapeId
    )
    await deleteHistory((latestID))
    console.log('newInsertedId:', newInsertedId)
    console.log('currentShape:', currentShape)
  }
}
