<template>
  <div
    class="modal card"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showHistory }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" @click="closeModal">close</button>
        <h3 class="title is-6 has-text-success">History of actions</h3>
        <a
          class="panel-block"
          v-for="snapshot in snapshots"
          :key="snapshot.id"
          style="background-color: white; text-decoration: none; color: black"
        >
          <!-- TODO: WHAT'S THIS? -->
          <!-- <input type="checkbox" @change="toggleHistory(snapshot, snapshot.id)"> -->
          {{ snapshot.action }} -
          {{
            $moment($moment(snapshot.date).toDate()).format(
              "MMMM Do dddd h:mm A"
            )
          }}
        </a>
        <div class="panel-block">
          <button
            class="button is-info is-fullwidth is-small"
            v-bind:class="undoLoading ? ' is-loading' : ''"
            @click="getLatestAction"
          >
            Undo
          </button>
          <button
            class="button is-warning is-fullwidth is-small"
            v-bind:class="undoLoading ? ' is-loading' : ''"
          >
            Delete
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  getHistories,
  mongoDbAppForHistory,
  /* TODO */
  deleteHistory,
} from "@/store/mongoStore";
import historyStore from "@/store/historyStore";

export default {
  data() {
    return {
      snapshots: [],
      selectedSnapshots: [],
      undoLoading: false,
    };
  },
  props: {
    showHistory: {
      type: Boolean,
    },
  },
  async mounted() {
    const histories = await getHistories();
    this.snapshots = histories;
    const mongoAppForHistory = await mongoDbAppForHistory();
    const snapshotsCollection = mongoAppForHistory.collection("snapshots");
    for await (const change of snapshotsCollection.watch()) {
      switch (change.operationType) {
        case "insert":
        case "update":
        case "replace":
        case "delete": {
          this.undoLoading = true;
          const histories = await getHistories();
          this.snapshots = histories;
          this.undoLoading = false;
          break;
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    toggleHistory(snapshot, id) {
      if (!this.selectedSnapshots.find((x) => x.id === id)) {
        this.selectedSnapshots.push({
          ...snapshot,
        });
      } else {
        this.selectedSnapshots = this.selectedSnapshots.filter(
          (x) => x.id !== id
        );
      }
      console.log("selectedSnapshots: ", this.selectedSnapshots);
    },
    async getLatestAction() {
      this.undoLoading = true;
      const snapshots = this.snapshots;
      const latestSnapshot = snapshots[0];
      console.log(snapshots);
      const {
        payload: latestPayload,
        action: latestAction,
        _id,
      } = latestSnapshot;
      await historyStore(latestAction, latestPayload, String(_id));
      this.undoLoading = false;
      return true;
    },
  },
};
</script>
